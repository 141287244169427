import type { IContentInfoOVP, VideoSourceInfoOVP } from '@setplex/tria-api'
import { createEvent, createStore, type EventCallable } from 'effector'

export const play: EventCallable<VideoSourceInfoOVP> = createEvent()
export const stop: EventCallable<VideoSourceInfoOVP> = createEvent()
export const watchingContent: EventCallable<IContentInfoOVP> = createEvent()
export const playerWatching: EventCallable<VideoSourceInfoOVP> = createEvent()
export const playerEvent: EventCallable<VideoSourceInfoOVP> = createEvent()

export const setPlayerSessionId = createEvent<string>()

export const $playerSessionId = createStore<string>('').on(
  setPlayerSessionId,
  (_, data) => data
)
