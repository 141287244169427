import type { Tag } from '@setplex/tria-api'
import { toDate } from '../../tools'
import { format as formatContent } from '../content/format'
import type { ApiTag } from './index.h'

export const format = ({
  id,
  title,
  description,
  horizontalImage,
  background,
  content,
  createDate,
}: ApiTag): Tag => ({
  id,
  title,
  description,
  horizontalImage,
  background,
  content:
    content && content.length ? content.map(formatContent) : (content as any),
  createDate: toDate(createDate),
})
