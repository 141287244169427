import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
  type Effect,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'
import { Action, type History, type Location, type To } from './index.h'
import { init as addRoutes, type Route } from './router'

//
// base history events and stores
//

export const init: EventCallable<{ history: History; routes: Route }> =
  createEvent()
export const locationUpdated: EventCallable<{
  location: Location
  action: Action
}> = createEvent()

export const $history: StoreWritable<N<History>> = createStore<N<History>>(null) //
  .on(init, (__, { history }) => history)
export const $location: StoreWritable<N<Location>> = createStore<N<Location>>(
  null
)
  .on(init, (__, { history }) => history.location)
  .on(locationUpdated, (__, { location }) => location)
export const $action: StoreWritable<N<Action>> = createStore<N<Action>>(null)
  .on(init, () => Action.Pop)
  .on(locationUpdated, (__, { action }) => action)

// stores for parts of location
export const $pathname: Store<N<string>> = $location.map(prop('pathname'))
export const $search: Store<N<string>> = $location.map(prop('search'))
export const $hash: Store<N<string>> = $location.map(prop('hash'))
export const $key: Store<N<string>> = $location.map(prop('key'))
export const $state: Store<N<unknown>> = $location.map(prop('state'))
export const $href: Store<N<string>> = $location.map((location) =>
  location ? location.pathname + location.search : null
)

// parsed query string store
export const $query: Store<URLSearchParams> = $search.map(
  (search) => new URLSearchParams(search || undefined)
)

//
// get previous location
//

const $locations: Store<[N<Location>?, N<Location>?]> = $location.map(
  (current, previous = []) => [previous[1], current]
)

export const $previous: Store<N<Location>> = $locations.map(
  ([location]) => location ?? null
)

//
// history subscription management
//

type Unsub = () => void
type N<T> = T | null

const subscribeToLocationFx: Effect<
  { unlisten: N<Unsub>; history: History },
  Unsub
> = createEffect<{ unlisten: N<Unsub>; history: History }, Unsub>(
  ({ unlisten, history }) => {
    if (unlisten) unlisten()
    return history.listen(locationUpdated)
  }
)

const $locationSubscription: StoreWritable<N<Unsub>> = restore(
  subscribeToLocationFx,
  null
)

sample({
  clock: init,
  source: $locationSubscription,
  fn: (unlisten, { history }) => ({ unlisten, history }),
  target: subscribeToLocationFx,
})

//
// handle navigations
//

type ToEx = To & { state?: unknown }

export const navigatePush: EventCallable<ToEx> = createEvent()
export const navigateReplace: EventCallable<ToEx> = createEvent()
export const navigateGo: EventCallable<number> = createEvent()
export const navigateBack: EventCallable<void> = createEvent()
export const navigateForward: EventCallable<void> = createEvent()

const navigatePushFx: Effect<ToEx, void> = attach({
  source: $history,
  effect: (history, to: ToEx) => history?.push(to, to.state),
})

const navigateReplaceFx: Effect<ToEx, void> = attach({
  source: $history,
  effect: (history, to: ToEx) => history?.replace(to, to.state),
})

const navigateGoFx: Effect<number, void> = attach({
  source: $history,
  effect: (history, delta: number) => history?.go(delta),
})

const navigateBackFx: Effect<void, void> = attach({
  source: $history,
  effect: (history) => history?.back(),
})

const navigateForwardFx: Effect<void, void> = attach({
  source: $history,
  effect: (history) => history?.forward(),
})

sample({ clock: navigatePush, target: navigatePushFx })
sample({ clock: navigateReplace, target: navigateReplaceFx })
sample({ clock: navigateGo, target: navigateGoFx })
sample({ clock: navigateBack, target: navigateBackFx })
sample({ clock: navigateForward, target: navigateForwardFx })

//
// Refresh current route
// This is dumb event, which does nothing here,
// but it is handled by navigation process, causeing retriggering route's `wait` and `init` events
//

export const refresh: EventCallable<void> = createEvent()

//
// transitions
//

export const transitionStart: EventCallable<void> = createEvent()
export const transitionEnd: EventCallable<void> = createEvent()

const $transactionsCount: StoreWritable<number> = createStore(0)
  .on(transitionStart, (count) => count + 1)
  .on(transitionEnd, (count) => count - 1)

export const $ongoingTransition: Store<boolean> = $transactionsCount.map(
  (count) => count > 0
)

//
// helper functions
//

function prop<K extends keyof Location>(
  property: K
): (location: N<Location>) => N<Location[K]> {
  return (location) =>
    location ? (location[property] as string) || null : null
}

//
// Add application routes to the router
//

// add top-level routes to the router
// this effect is executed synchronously, so it should run before any location listeners
const addRoutesFx: Effect<Route, void> = createEffect(addRoutes)

sample({
  clock: init,
  fn: ({ routes }) => routes,
  target: addRoutesFx,
})
