import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format as contentFormat } from '../content/format'
import type { ApiContent } from '../content/index.h'
import { format, formatCreatorTag } from './format'
import type { ApiCreator, ApiCreatorTag } from './index.h'

export function use(
  http: HttpClient,
  creators: API['creators'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // *
  // * base API effects
  // *

  // GET /api/web/creators
  creators.base.getManyFx.use(
    async ({
      limit,
      offset,
      by = _defaults.sortBy,
      order = _defaults.sortOrder,
      q,
    }) => {
      const params = query({ limit, offset, sortBy: by, sortOrder: order, q })
      const json = await http.get<
        GenericApiResponse<{ results: ApiCreator[] }>
      >(`/creators${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in creators.base.getManyFx')
      }

      return (json.payload.results || []).map(format)
    }
  )

  // GET /api/web/creators/{id}
  creators.base.getOneFx.use(async ({ id }) => {
    const json = await http.get<GenericApiResponse<ApiCreator>>(
      `/creators/${id}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in creators.base.getOneFx')
    }

    return format(json.payload)
  })

  // GET /api/web/creators/{id}/content
  creators.base.getContentFx.use(async ({ id, limit, offset, by, order }) => {
    const params = query({
      limit,
      offset,
      sortBy: by,
      sortOrder: order,
    })

    const json = await http.get<GenericApiResponse<{ results: ApiContent[] }>>(
      `/creators/${id}/content${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in creators.base.getContentFx')
    }

    return (json.payload.results || []).map(contentFormat)
  })

  // GET /api/web/creators/{id}/content-tags{params}
  creators.base.getContentTagsFx.use(
    async ({ id, limit, offset, by, order, withContent, contentCount }) => {
      const params = query({
        limit,
        offset,
        sortBy: by,
        sortOrder: order,
        withContent,
        contentCount,
      })
      const json = await http.get<
        GenericApiResponse<{ results: ApiCreatorTag[] }>
      >(`/creators/${id}/content-tags${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in creators.base.getContentTagsFx')
      }

      return (json.payload.results || []).map(formatCreatorTag)
    }
  )
}
