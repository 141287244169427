import { replaceSpacesWithHyphens } from './strings'

/**
 * Function replace all spaces with hyphens in string, make it lower case.
 * Used, for example, to insert hyphens into the title before inserting it into the URL
 * @param title
 */
export const formatTitleForUrl = (title: string | undefined): string => {
  if (!title) return ''
  return `${replaceSpacesWithHyphens(encodeURIComponent(title.toLowerCase()))}-`
}

/**
 * Function get substring after last hyphens in string.
 * Used to get 'id' 93 from URL /category/sports-news-93
 * @param string
 */
export const getIdFromUrl = (string = ''): number | null => {
  const id = Number(string.split('-').pop())
  return id || null
}

/**
 * Get id and title from part of url like 'creator-name-117'
 * @param string
 */
export const getDataFromUrl = (
  string = ''
): { id: number | null; title: string } => {
  const index = string.lastIndexOf('-')
  return {
    id: Number(string.substring(index + 1)) || null,
    title: string.substring(0, index),
  }
}
