/**
 * Convert date object to string like '19 August 2022'
 * @param date
 */
export const formatDate = (date: Date): string => {
  if (!date) return ''

  const day = date.getDate()
  const month = date.toLocaleString('en-GB', { month: 'long' })
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

/**
 * Convert date object to string like '1900-02-27'
 * @param date
 */
export const formatDateToString = (date: Date): string => {
  if (!date) return ''

  return [
    String(date.getFullYear()),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
  ].join('-')
}

/**
 * Convert date object in the format hh-mm-ss to string like '18-25-44'
 * @param date
 */
export const formatTimeToString = (date: Date): string => {
  if (!date) return ''

  return [
    String(date.getHours()).padStart(2, '0'),
    String(date.getMinutes()).padStart(2, '0'),
    String(date.getSeconds()).padStart(2, '0'),
  ].join('-')
}

/**
 * Convert date object to string like '19/02/2014'
 * @param date
 */
export const formatDateByPattern = (date: Date): string => {
  if (!date) return ''

  return date.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

/**
 * Convert string like '19/02/2014' to Date object
 * @param string
 */
export const formatStringToDate = (string: string): Date | null => {
  if (!string) return null

  return new Date(string)
}

/**
 * Return only year from date object as string like '2022'
 * @param date
 */
export const getYear = (date: Date): string => {
  if (!date) return ''

  const year = date.getFullYear()

  return `${year}`
}

/**
 * Return minutes between two dates
 * @param date
 */
export const getMinutesDifference = (date1: number, date2: number): number => {
  if (!date1 || !date2) return 0

  const minutes = Math.abs(date1 - date2) / 1000 / 60
  return minutes
}
