import type { AnalyticsInfoOVP, API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { logger } from '../../utils/logger'
import { postAnalytics, request } from './request'

const common = (info: AnalyticsInfoOVP) => ({
  app_releaseVersion: info.appVersion,
  app_name: info.appName,
  app_id: info.appId,
  device_OsVersion: info.browserVersion,
  device_model: info.browserName,
  device_name: info.osName,
  device_id: info.userAgent,
  network_ip: info.networkIp,
  network_location: info.location,
  content_user_subscriber_id: info.contentUserSubscriberId,
  content_user_profile_name: info.windowSessionGuid,
  content_user_account_number: info.contentUserAccountNumber,
  content_user_profile_id: info.contentUserProfileId,
  content_provider_id: info.contentProviderId,
  event_parameter16: info.preferredLanguage,
})

export function use(
  _http: HttpClient,
  analytics_ovp: API['analytics_ovp'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // `session_start` event
  analytics_ovp.sendAnalyticsSessionStartFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter7: data.customErrorCode,
      event_parameter9: data.sessionId, //Unique ID Session — generated by client
    }
    logger.info('Send analytics session start event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `session_end` event
  analytics_ovp.sendAnalyticsSessionStopFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter9: data.sessionId, //Unique ID Session — generated by client
    }
    logger.info('Send analytics session stop event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `favorite_add` event
  analytics_ovp.sendAnalyticsFavoriteAddFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId, // Unique content ID
      event_parameter2: data.contentType, // Content type.
      event_parameter3: data.contentUrl, // URL for playing content
      event_parameter4: data.contentTitle, // content title, For Movie - name of movie
      event_parameter8: data.creatorId, // Creator ID
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
    }
    logger.info('Send analytics favorite add event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `favorite_remove` event
  analytics_ovp.sendAnalyticsFavoriteRemoveFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId, // Unique content ID
      event_parameter2: data.contentType, // Content type.
      event_parameter3: data.contentUrl, // URL for playing content
      event_parameter4: data.contentTitle, // content title, For Movie - name of movie
      event_parameter8: data.creatorId, // Creator ID
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
    }
    logger.info('Send analytics favorite remove event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `play_start` event
  analytics_ovp.sendAnalyticsStartWatchingFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
      event_parameter3: data.contentUrl,
      event_parameter4: data.audioLanguage,
      event_parameter5: data.textLanguage,
      event_parameter6: data.startedTime,
      event_parameter7: data.duration,
      event_parameter8: data.creatorId,
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
      event_parameter10: data.playerSessionId, // Unique ID Session — generated by client
    }
    logger.info('Send analytics player start event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `play_stop` event
  analytics_ovp.sendAnalyticsStopPauseWatchingFx.use(
    async ({ domain, data }) => {
      const formattedData = {
        ...common(data),
        event_type: data.eventType,
        event_parameter1: data.contentId,
        event_parameter2: data.contentType,
        event_parameter3: data.contentUrl,
        event_parameter4: data.audioLanguage,
        event_parameter5: data.textLanguage,
        event_parameter6: data.stoppedTime,
        event_parameter7: data.duration,
        event_parameter8: data.creatorId,
        event_parameter9: data.sessionId, // Unique ID Session — generated by client
        event_parameter10: data.playerSessionId, // Unique ID Session — generated by client
      }
      logger.info('Send analytics player stop event data:', formattedData)
      await postAnalytics(domain, request(formattedData))
    }
  )

  // `player_watching` event
  analytics_ovp.sendAnalyticsPlayerWatchingFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
      event_parameter3: data.contentUrl,
      event_parameter4: data.audioLanguage,
      event_parameter5: data.textLanguage,
      event_parameter6: data.stoppedTime,
      event_parameter7: data.duration,
      event_parameter8: data.creatorId,
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
      event_parameter10: data.playerSessionId, // Unique ID Session — generated by client
    }
    logger.info('Send analytics player watching event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `player_event` event
  analytics_ovp.sendAnalyticsPlayerEventFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
      event_parameter3: data.contentUrl,
      event_parameter4: data.audioLanguage,
      event_parameter5: data.textLanguage,
      event_parameter6: data.startedTime,
      event_parameter7: data.duration,
      event_parameter8: data.creatorId,
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
      event_parameter10: data.playerSessionId, // Unique ID Session — generated by client
      event_parameter11: data.playerEventType,
    }
    logger.info('Send analytics player_event event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `player_open` event
  analytics_ovp.sendAnalyticsPlayerOpenFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.contentId,
      event_parameter2: data.contentType,
      event_parameter3: data.contentUrl,
      event_parameter4: data.contentTitle,
      event_parameter6: data.contentOpenFrom,
      event_parameter7: data.contentErrorCode,
      event_parameter8: data.creatorId,
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
      event_parameter10: data.playerSessionId, // Unique ID Session — generated by client
    }
    logger.info('Send analytics player open event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `search` event
  analytics_ovp.sendAnalyticsSearchFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter1: data.searchInput,
      event_parameter2: data.contentSearchType,
      event_parameter9: data.sessionId, // Unique ID Session — generated by client
    }

    logger.info('Send analytics search event data:', formattedData)
    await postAnalytics(domain, request(formattedData))
  })

  // `page_open` event
  analytics_ovp.sendAnalyticsPageOpenFx.use(async ({ domain, data }) => {
    const formattedData = {
      ...common(data),
      event_type: data.eventType,
      event_parameter2: data.path,
      event_parameter3: data.entityId,
      event_parameter4: data.contentTitle,
      event_parameter8: data.creatorId,
      event_parameter9: data.sessionId, //Unique ID Session — generated by client
      event_parameter11: data.utm_source,
      event_parameter12: data.utm_medium,
      event_parameter13: data.utm_campaign,
      event_parameter14: data.utm_term,
      event_parameter15: data.utm_content,
    }
    logger.info('Send analytics page open event data:', formattedData)

    await postAnalytics(domain, request(formattedData))
  })
}
