export const OPEN_FROM = {
  BANNERS: 'Banners',
  HOME: 'Home',
  BROWSE: 'Browse',
  RECOMMENDED: 'Recommended',
  FAVORITES: 'Favorites',
  CREATOR: 'Creator page',
  SEARCH: 'Search results',
  SEARCH_CREATOR_LATEST_CONTENT: 'Search creators latest content',
  DIRECT_LINK: 'Direct link',
  LIVE: 'live',
}
