import { type LiveChannelFormatted } from '$/channels'
import {
  CARDS_ASPECT_RATIO,
  CardsInRow,
  LiveChannelCard,
  RowContainerTemplate,
  RowControlsType,
  type IRowContainerProps,
} from '@setplex/arcane'
import { MediaTypes } from '@setplex/tria-api'
import { useUnit } from 'effector-react'
import { useCallback, type SyntheticEvent } from 'react'
import { model as favoritesModel } from '~/features/favorites'
import { LiveChannelRowCardsCount } from '../../constants'

interface IRowProps {
  id?: number | string
  content?: LiveChannelFormatted[]
  title: IRowContainerProps['title']
  background?: IRowContainerProps['background']
  cardsCount?: LiveChannelRowCardsCount
  isSeeAllBtnShown?: boolean
  seeAllBtnClick?: ({ id, title }?: { id: number; title: string }) => void
  className?: string
  handleClick?: (data: string) => void
}

ChannelRow.displayName = 'ChannelRow'
export function ChannelRow({
  id,
  content,
  title = '',
  background,
  className,
  cardsCount = LiveChannelRowCardsCount.DEFAULT,
  isSeeAllBtnShown,
  seeAllBtnClick,
  handleClick,
}: IRowProps): JSX.Element | null {
  const contentLength = content?.length ?? 0
  const isMoreThanOnePage = isSeeAllBtnShown ?? contentLength > cardsCount
  const favorite = useUnit(favoritesModel.$favorites)
  const handleFavoriteBtnClick = useUnit(favoritesModel.handleFavoriteBtnClick)
  const sliced = content?.slice(0, cardsCount) ?? []
  const slicedLength = sliced.length

  const onClick = () => {
    handleClick?.(title)
  }

  const handleSeeAllBtnClick = useCallback(() => {
    if (!seeAllBtnClick) return

    if (typeof id === 'number') {
      return seeAllBtnClick({ id, title })
    }
    return seeAllBtnClick() // Case if navigate to favorites for example
  }, [id, title, seeAllBtnClick])

  return slicedLength ? (
    <RowContainerTemplate
      className={className}
      title={title}
      itemCount={slicedLength}
      background={background}
      cardsInRow={CardsInRow.Horizontal_4}
      tvChannelCard
      cardAspectRatio={CARDS_ASPECT_RATIO.HORIZONTAL_DEFAULT}
      controlsType={RowControlsType.inRowFluid}
      isMoreThanOnePage={isMoreThanOnePage}
      onSeeAllClick={handleSeeAllBtnClick}
      handleClick={onClick}
    >
      {sliced.map((card, index) => {
        const { id, logo, link, url, title: channelTitle } = card

        const isFavorite = Boolean(favorite.live_channel[id])
        const onClick = (e: SyntheticEvent) => {
          e.preventDefault()
          e.stopPropagation()

          handleFavoriteBtnClick?.({
            id,
            isFavorite: !isFavorite,
            contentType: MediaTypes.LIVE_CHANNEL,
            creatorId: '',
            contentTitle: channelTitle,
            contentUrl: url,
          })
        }

        return (
          <a key={`${index}${id}`} href={link}>
            <LiveChannelCard
              posterUrl={logo}
              isFavorite={isFavorite}
              handleFavoriteClick={onClick}
            />
          </a>
        )
      })}
    </RowContainerTemplate>
  ) : null
}
