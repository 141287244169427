import { createEffect } from 'effector'
import type { PageParams } from '../../interfaces/fxParams'
import type { GenericApiResponse } from '../../interfaces/generic'
import { stateful } from '../../lib/stateful'
import type { Content } from '../content'

type EntityParams = {
  hashtag?: string
}

type BaseParams = EntityParams & PageParams

export const base = {
  getContentFx: createEffect<
    BaseParams,
    Content[],
    GenericApiResponse<Error>
  >(),
}

export const pageable = (initial?: BaseParams) =>
  stateful({
    effect: base.getContentFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })
