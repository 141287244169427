import { model as flags } from '!/flags'
import { model as launch } from '!/launch'
import { firebaseSharedModel as firebase } from '@/firebase'
import { createEvent, sample, type EventCallable } from 'effector'
import { model as session } from '~/entities/session'
import { analytics } from '~/features/analytics'
import { googlePalFeatureModel } from '~/features/google-pal'
import { live } from '~/features/live'
import { preferences } from '~/features/preferences'
import { profile } from '~/features/profile'

export const init: EventCallable<void> = createEvent()

//
// application launch
//

sample({
  clock: init,
  target: launch.init,
})

// init flags and features
sample({
  clock: init,
  target: [flags.init, firebase.init],
})

// init session (get from BE)
sample({
  clock: init,
  target: session.init,
})

// init features
sample({
  clock: init,
  target: [googlePalFeatureModel.init, preferences.init, profile.init],
})

// init analytics
sample({
  clock: init,
  target: analytics.init,
})

// init live
sample({
  clock: init,
  target: live.init,
})
