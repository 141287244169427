import { api, MediaTypes } from '@setplex/tria-api'
import {
  createEffect,
  createEvent,
  createStore,
  sample,
  split,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { model as session } from '~/entities/session'
import { model as signInModel } from '~/widgets/auth/sign-in'
import { type Favorites } from './index.h'
import { update } from './lib'

type FavoriteItem = {
  id: number
  isFavorite: boolean
  contentType: MediaTypes
  creatorId?: number | string
  contentUrl?: string
  contentTitle?: string
}

export const handleFavoriteBtnClick: EventCallable<FavoriteItem> = createEvent()
export const handleIsFavorite: EventCallable<FavoriteItem> = createEvent()

const add: EventCallable<FavoriteItem> = createEvent()
const remove: EventCallable<FavoriteItem> = createEvent()

const reportUnknownTypeFx = createEffect((type: MediaTypes) => {
  console.log('processing of favorite content has fallen, unknown type:', type)
})

// Movies
const addMovieToFavoritesFx: typeof api.content.base.addToFavoritesFx =
  api.content.base.addToFavoritesFx
const removeMovieFromFavoritesFx: typeof api.content.base.removeFromFavoritesFx =
  api.content.base.removeFromFavoritesFx

// Live channels
const addLiveChannelToFavoritesFx: typeof api.liveChannel.base.addToFavoritesFx =
  api.liveChannel.base.addToFavoritesFx
const removeLiveChannelFromFavoritesFx: typeof api.liveChannel.base.removeFromFavoritesFx =
  api.liveChannel.base.removeFromFavoritesFx

sample({
  clock: handleFavoriteBtnClick,
  target: signInModel.tryToOpenSignInPopup,
})

sample({
  clock: handleFavoriteBtnClick,
  filter: session.$isAuthenticated,
  target: handleIsFavorite,
})

split({
  source: handleIsFavorite,
  match: {
    add: ({ isFavorite }) => Boolean(isFavorite),
    remove: ({ isFavorite }) => !isFavorite,
  },
  cases: {
    add,
    remove,
  },
})

const $type = createStore<MediaTypes | null>(null).on(
  handleIsFavorite,
  (_, { contentType }) => contentType
)

// Handle add
split({
  source: add,
  match: $type,
  cases: {
    [MediaTypes.VIDEO]: addMovieToFavoritesFx,
    [MediaTypes.LIVE_CHANNEL]: addLiveChannelToFavoritesFx,
    __: reportUnknownTypeFx,
  },
})

// Removal handling
split({
  source: remove,
  match: $type,
  cases: {
    [MediaTypes.VIDEO]: removeMovieFromFavoritesFx,
    [MediaTypes.LIVE_CHANNEL]: removeLiveChannelFromFavoritesFx,
    __: reportUnknownTypeFx,
  },
})

export const $favorites: StoreWritable<Favorites> = createStore({
  live_channel: {},
  video: {},
})
  .reset(session.$isAuthenticated)
  .on(
    [
      api.content.base.getOneFx.doneData,
      api.content.base.getManyFx.doneData,
      api.content.base.getManyOldFx.doneData,
      api.content.base.getRecommendedFx.doneData,
      api.content.base.getFavoritesFx.doneData,
      api.creators.base.getContentTagsFx.doneData,
      api.creators.base.getContentFx.doneData,
      api.carousels.base.getContentFx.doneData,
      addMovieToFavoritesFx.done,
      removeMovieFromFavoritesFx.done,
    ],
    update('video')
  )
  .on(
    [
      api.liveChannel.base.getLiveChannelsFx.doneData,
      api.liveChannel.base.getLiveChannelsByCategoryFx.doneData,
      api.liveChannel.base.getFavoritesFx.doneData,
      addLiveChannelToFavoritesFx.done,
      removeLiveChannelFromFavoritesFx.done,
    ],
    update('live_channel')
  )
