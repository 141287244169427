import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import { query } from '../../tools'
import { format } from '../content/format'
import type { ApiContent } from '../content/index.h'

const HASHTAG_API = '/hashtag'

export function use(
  http: HttpClient,
  hashtag: API['hashtag'],
  _api: API
): void {
  // GET /api/web/hashtag/:hashtag/contents
  // In order for the correct request results to be returned, there must be a complete match of the hashtag name (including special characters)
  hashtag.base.getContentFx.use(async ({ hashtag, limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<GenericApiResponse<{ results: ApiContent[] }>>(
      `${HASHTAG_API}/${hashtag}/contents${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in hashtag.base.getContentFx')
    }

    return (json.payload.results || []).map(format)
  })
}
