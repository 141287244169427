import { type Value } from 'firebase/remote-config'

export type Remote = typeof defaults
export type RemoteKeys = keyof Remote

// filter function for remote config keys
export const byKey = (_: unknown, key: string) => key.startsWith('uvo_')

export const defaults = {
  uvo_isGooglePalEnabled: false,
  uvo_analyticsListenerDomain: '',
  uvo_macrosHeader: 'ad-client-params',
  uvo_customerDashboardSignUpUrl: '',
  uvo_customerDashboardUrl: '',
  uvo_isOnboardingEnabled: false,
  uvo_playerWatchingEventInterval: 0,
  uvo_domainUrl: '',
}

export const remote = Object.keys(defaults).reduce((acc, key) => {
  acc[key] = key
  return acc
}, Object.create(null)) as { [K in RemoteKeys]: K }

// prettier-ignore
export function asType(key: RemoteKeys, value: Value, deftype = 'string') {
  const type = typeof defaults[key]

  // this big ternary is quite readable and understandable in my opinion
  /* eslint-disable no-nested-ternary */
  /* @formatter:off */
  return type === 'boolean' ? value.asBoolean()
       : type === 'number'  ? value.asNumber()
       : type === 'string'  ? value.asString()

       // if there is no such value in defaults -> use default type
       : deftype === 'boolean' ? value.asBoolean()
       : deftype === 'number'  ? value.asNumber()
       : deftype === 'string'  ? value.asString()
                               : value.asString()

  /* eslint-enable no-nested-ternary */
}
