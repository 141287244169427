/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { features } from '!/flags'
import {
  Action,
  model as router,
  scroll,
  stage,
  router as universalRouter,
  type Location,
  type RouterResponse,
  type Staged,
} from '!/router'
import { addLinkCanonical, addMetaRobots, exists, nay, noop } from '@/helpers'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  launch,
  sample,
  type Effect,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { env } from '~/app/environment'
import { model as launchModel } from '~/core/launch'
import { model as session } from '~/entities/session'
import { PATH } from '~/shared/constants/path'

// initialize process, doing nothing, should remove it?
export const init: EventCallable<void> = createEvent()

// start preparations to render exact route (e.g. call `wait` effect)
const prerender: EventCallable<RouterResponse> = createEvent()

// start actual render of exact route
const render: EventCallable<RouterResponse> = createEvent()

// pre-set route-to-be
export const setRoute: EventCallable<RouterResponse> = createEvent()

// current loaded route
export const $route: StoreWritable<RouterResponse | null> =
  createStore<RouterResponse | null>(null)

// resolve route from location, using universal router
export const resolveRouteFx: Effect<
  Location,
  RouterResponse | null | undefined
> = attach({
  source: {
    features: features.$,
    session: session.$user, // TODO: session is not available on page reload :(
  },
  effect: async ({ features, session }, location: Location) =>
    universalRouter.resolve({
      pathname: location.pathname,
      query: new URLSearchParams(location.search),
      features,
      session,
    }),
})

// update browser title from route
const titleFx: Effect<RouterResponse, void> = createEffect<
  RouterResponse,
  void
>(() => {
  // TODO: remove this and related logics if SEO optimization (about document.title) works
  // document.title = route.title || 'UVOtv'
})

// SEO
const seoIndexingFx: Effect<RouterResponse, void> = createEffect<
  RouterResponse,
  void
>((route) => {
  addMetaRobots(
    env.UVO_FIREBASE_CONFIG === 'development' ? false : route.seoIndexing
  )
  addLinkCanonical()
})

// awaits for blocking calls before render
const waitFx: Effect<RouterResponse & Staged, void> = createEffect<
  RouterResponse & Staged,
  void
>((route) => {
  if (route.wait) {
    // `wait` should be an Effect, to be able to await it
    return route.wait({ ...route.props, stage: route.stage })
  }
})

// init non-blocking calls in parallel with render
const initFx: Effect<RouterResponse & Staged, void> = createEffect<
  RouterResponse & Staged,
  void
>((route) => {
  if (route.init) {
    // `init` could be any effector's unit, usually an Event
    launch(route.init, { ...route.props, stage: route.stage })
  }
})

// trigger left route actions
const leftFx: Effect<RouterResponse, void> = attach({
  source: $route,
  effect(fromRoute, toRoute: RouterResponse) {
    if (fromRoute && toRoute && fromRoute !== toRoute && fromRoute.left) {
      // `left` could be any effector's unit, usually an Event
      // can pass from and to routes here, if needed
      launch(fromRoute.left, { ...fromRoute.props })
    }
  },
})

// effect to render application, should be implemented _outside_ of model
export const renderFx: Effect<
  { location: Location; route: RouterResponse },
  void
> = createEffect()

//
// react on location change
//

// resolve route on each location change
sample({
  clock: [router.$location, launchModel.$isLaunchDataReady],
  source: {
    isLaunchDataReady: launchModel.$isLaunchDataReady,
    location: router.$location,
  },
  filter: ({ isLaunchDataReady, location }) =>
    isLaunchDataReady && exists(location),
  fn: ({ location }) => location,
  target: resolveRouteFx,
})

// when route is resolved - check, if we still on the same location, as we were before,
// and route exists at all
sample({
  clock: resolveRouteFx.done,
  source: router.$key,
  filter: (key, { params, result }) => params.key === key && exists(result),
  fn: (__, { result }) => result!,
  target: setRoute,
})

// some error happen when resolving route -> redirect to error page
sample({
  clock: resolveRouteFx.fail,
  filter: ({ params }) => nay(params.pathname.startsWith(PATH.ERROR)), // prevent infinite cycle
  fn: (state) => ({ pathname: PATH.ERROR, state }),
  target: router.navigatePush,
})
resolveRouteFx.fail.watch((e) => console.error('🔁 router.resolve:', e))

// start transaction on resolve route start
sample({
  clock: resolveRouteFx,
  fn: noop,
  target: router.transitionStart,
})

// finish transaction on resolve route finish
sample({
  clock: resolveRouteFx.finally,
  fn: noop,
  target: router.transitionEnd,
})

//
// check route validity
//

// handles redirects first
// in case new route has `redirect` field -> replace current location
sample({
  clock: setRoute,
  filter: (route) => exists(route.redirect),
  fn: (route) => route.redirect!,
  target: router.navigateReplace,
})

// in case new route has no `redirect` field -> actually change route
// but before -> call `left` event for current route
sample({
  clock: setRoute,
  filter: (route) => nay(exists(route.redirect)),
  target: leftFx,
})

// and then -> actually set new route
sample({
  clock: leftFx.finally,
  fn: ({ params }) => params,
  target: $route,
})

//
// update and render actual rendered route
//

// update browser title on route change
sample({
  clock: $route,
  filter: exists,
  target: [titleFx, seoIndexingFx],
})

// finally render requested and resolved route
sample({
  clock: $route,
  filter: exists,
  target: prerender,
})

//
// render process
//

// awaits for blocking initialization, if any
sample({
  clock: prerender,
  fn: stage.navigate,
  target: [router.transitionStart, waitFx],
})

// render and launch non-blocking initialization, if any
sample({
  clock: waitFx.finally,
  source: prerender,
  filter: (_, { params }) => params.stage === 'navigate', // to avoid rerender on `refresh` stage
  fn: stage.navigate,
  target: [router.transitionEnd, initFx, render],
})

// call actual render effect with current location
// TODO: add filter on changed location? what if while we' ve waited `waitFx`, user has go elsewhere
sample({
  clock: render,
  source: router.$location,
  filter: (location, route) => exists(location) && exists(route),
  fn: (location, route) => ({ location: location!, route }),
  target: renderFx,
})

//
// Refresh current route
//

// in case refresh requested -> get last result of `prerender` event,
// which should be currently rendered route,
// and call `wait` and `init` for that route
sample({
  clock: router.refresh,
  source: prerender,
  fn: stage.refresh,
  target: [waitFx, initFx],
})

//
// handle scroll position
//

// save scroll position of previous location
sample({
  clock: router.$previous,
  filter: (location) => exists(location?.key),
  fn: (location) => location?.key!, // eslint-disable-line @typescript-eslint/no-non-null-asserted-optional-chain
  target: scroll.pushFx,
})

// clear scroll position in case action is Push (= go to new page)
sample({
  clock: router.$action,
  source: router.$key,
  filter: (key, action): key is string => exists(key) && action === Action.Push,
  target: scroll.clearFx,
})

// restore scroll position (or just scroll up)
sample({
  clock: renderFx.finally,
  source: router.$key,
  filter: exists,
  target: scroll.applyFx,
})
