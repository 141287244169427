import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format } from './format'
import type { ApiContent } from './index.h'

const GET_BY_TAG = 'by-tag'
const GET_BY_COLLECTION = 'by-collection'

export function use(
  http: HttpClient,
  content: API['content'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // *
  // * base API effects
  // *

  // GET /api/web/content
  // Used at search page for content loading in grid
  content.base.getManyOldFx.use(
    async ({
      limit,
      offset,
      by = _defaults.sortBy,
      order = _defaults.sortOrder,
      q,
      tagIds,
      collectionIds,
      creatorId,
    }) => {
      const params = query({
        limit,
        offset,
        sortBy: by,
        sortOrder: order,
        q,
        tagIds,
        collectionIds,
        creatorId,
      })

      const json = await http.get<
        GenericApiResponse<{ results: ApiContent[] }>
      >(`/content${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in content.base.getManyOldFx')
      }

      return (json.payload.results || []).map(format)
    }
  )

  // GET /api/web/content/by-tag/{id} (by default), /api/web/content/by-collection/{id}
  content.base.getManyFx.use(
    async ({
      getByTag = true,
      id,
      limit,
      offset,
      by = _defaults.sortBy,
      order = _defaults.sortOrder,
      q,
      creatorId,
    }) => {
      const params = query({
        limit,
        offset,
        sortBy: by,
        sortOrder: order,
        q,
        creatorId,
      })
      const getBy = getByTag ? GET_BY_TAG : GET_BY_COLLECTION

      const json = await http.get<
        GenericApiResponse<{ results: ApiContent[]; total: number }>
      >(`/content/${getBy}/${id}${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in content.base.getManyFx')
      }

      const results = (json.payload.results || []).map(format)

      return Object.defineProperty(results, 'total', {
        value: json.payload.total ?? 0,
        writable: false,
      })
    }
  )

  // GET /api/web/content/${id}
  content.base.getOneFx.use(async ({ id, headers = {} }) => {
    console.log('content.base.getOneFx id, headers', id, headers)
    const json = await http.get<GenericApiResponse<ApiContent>>(
      `/content/${id}`,
      {
        headers,
      }
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in content.base.getOneFx')
    }

    return format(json.payload)
  })

  // GET /api/web/content/recommended
  content.base.getRecommendedFx.use(
    async ({ limit, offset, by, order = _defaults.sortOrder, q }) => {
      const params = query({
        limit,
        offset,
        sortBy: by,
        sortOrder: order,
        q,
      })

      const json = await http.get<
        GenericApiResponse<{ results: ApiContent[] }>
      >(`/content/recommended${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in content.base.getRecommendedFx')
      }

      return (json.payload.results || []).map(format)
    }
  )

  // GET /api/web/content/my-list
  content.base.getFavoritesFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiContent[]; total: number }>
    >(`/content/my-list${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in content.base.getFavoritesFx')
    }

    const results = (json.payload.results || []).map(format)

    // Total field is used to show favorites quantity at the NavBar label
    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // POST /api/web/content/my-list/:id/add
  content.base.addToFavoritesFx.use(async ({ id }) => {
    const json = await http.post<GenericApiResponse<{}>>(
      `/content/my-list/${id}/add`
    )
    if (!json) {
      throw new Error('Empty answer in content.base.addToFavoritesFx')
    }
  })

  // POST /api/web/content/my-list/:id/remove
  content.base.removeFromFavoritesFx.use(async ({ id }) => {
    const json = await http.post<GenericApiResponse<{}>>(
      `/content/my-list/${id}/remove`
    )
    if (!json) {
      throw new Error('Empty answer in content.base.removeFromFavoritesFx')
    }
  })
}
