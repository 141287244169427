import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format } from './format'
import type { ApiCollection } from './index.h'

export function use(
  http: HttpClient,
  collections: API['collections'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // *
  // * base API effects
  // *

  // GET /api/web/collection/${id}
  collections.base.getOneFx.use(async ({ id, withContentTypes }) => {
    const params = query({
      withContentTypes,
    })
    const json = await http.get<GenericApiResponse<ApiCollection>>(
      `/collections/${id}${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in collections.base.getOneFx')
    }

    return format(json.payload)
  })

  // GET /api/web/collections
  collections.base.getManyFx.use(
    async ({
      ids,
      limit,
      offset,
      by = _defaults.sortBy,
      order = _defaults.sortOrder,
      q,
      hasContent,
    }) => {
      const params = query({
        ids,
        limit,
        offset,
        sortBy: by,
        sortOrder: order,
        q,
        hasContent,
      })
      const json = await http.get<
        GenericApiResponse<{ results: ApiCollection[] }>
      >(`/collections${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in collections.base.getManyFx')
      }

      return (json.payload.results || []).map(format)
    }
  )
}
