import { type LiveChannelFormatted } from '$/channels'
import {
  CardsInRow,
  LiveChannelCard,
  UVOTestGridTemplate,
  type ITestGridProps,
} from '@setplex/arcane'
import { MediaTypes } from '@setplex/tria-api'
import { useUnit } from 'effector-react'
import { type SyntheticEvent } from 'react'
import { model as favoritesModel } from '~/features/favorites'

interface IRowProps {
  className?: string
  content?: LiveChannelFormatted[] | null
  title?: ITestGridProps['title']
  background?: ITestGridProps['background']
  backgroundPosterEnabled?: ITestGridProps['backgroundPosterEnabled']
  backgroundPosterUrl?: ITestGridProps['backgroundPosterUrl']
  marginTitle?: ITestGridProps['marginTitle']
  updatedTitle?: boolean // New Text component will be rendered in Grid if true
  onLoadMoreItems?: () => void
}

ChannelGrid.displayName = 'ChannelGrid'
export function ChannelGrid({
  content,
  className = '',
  title = '',
  background,
  marginTitle,
  onLoadMoreItems,
}: IRowProps): JSX.Element {
  const favorite = useUnit(favoritesModel.$favorites)
  const handleFavoriteBtnClick = useUnit(favoritesModel.handleFavoriteBtnClick)

  return (
    <UVOTestGridTemplate
      title={title}
      updatedTitle
      onLoadMoreItems={onLoadMoreItems}
      className={className}
      cardsInRow={CardsInRow.Horizontal_4}
      tvChannelCard
      background={background}
      marginTitle={marginTitle}
    >
      {content?.length
        ? content.map((card, index) => {
            const { id, logo, link, url, title: channelTitle } = card

            const isFavorite = Boolean(favorite.live_channel[id])
            const onClick = (e: SyntheticEvent) => {
              e.preventDefault()
              e.stopPropagation()

              handleFavoriteBtnClick?.({
                id,
                isFavorite: !isFavorite,
                contentType: MediaTypes.LIVE_CHANNEL,
                creatorId: '',
                contentTitle: channelTitle,
                contentUrl: url,
              })
            }

            return (
              <a key={`${index}${id}`} href={link}>
                <LiveChannelCard
                  posterUrl={logo}
                  isFavorite={isFavorite}
                  handleFavoriteClick={onClick}
                />
              </a>
            )
          })
        : null}
    </UVOTestGridTemplate>
  )
}
