import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format } from './format'
import type { ApiTag } from './index.h'

export function use(
  http: HttpClient,
  tags: API['tags'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // *
  // * base API effects
  // *

  // GET /api/web/tags
  tags.base.getManyFx.use(
    async ({
      ids,
      limit,
      offset,
      by = _defaults.sortBy,
      order = _defaults.sortOrder,
      q,
      hasContent,
    }) => {
      const params = query({
        ids,
        limit,
        offset,
        sortBy: by,
        sortOrder: order,
        q,
        hasContent,
      })
      const json = await http.get<GenericApiResponse<{ results: ApiTag[] }>>(
        `/tags${params}`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in tags.base.getManyFx')
      }

      return (json.payload.results || []).map(format)
    }
  )

  // GET /api/web/tags/special
  tags.base.getManySpecialFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })
    const json = await http.get<GenericApiResponse<{ results: ApiTag[] }>>(
      `/tags/special${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in tags.base.getManySpecialFx')
    }

    return (json.payload.results || []).map(format)
  })
}
