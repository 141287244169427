import { MediaTypes, type LiveChannel } from '@setplex/tria-api'
import type { ApiLiveChannel } from './index.h'

export const format = ({
  id,
  number,
  url,
  title,
  description,
  ageRating,
  logo,
  banner,
  isFavorite,
}: ApiLiveChannel): LiveChannel => ({
  id,
  number,
  url,
  title,
  description,
  ageRating,
  logo,
  banner,
  isFavorite,
  contentType: MediaTypes.LIVE_CHANNEL,
})
