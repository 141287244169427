// organize-imports-ignore
// import './wdyr'
import './debug'
import '!/api'
import history from 'history/browser'
import ReactDOM from 'react-dom'
// import React, { StrictMode } from 'react'
// import { createRoot, type Root } from 'react-dom/client'
import { App, Bootstrap, env, model as app, versions } from '~/app'

const container: HTMLElement = document.getElementById('root') as HTMLElement
// const root: Root = createRoot(container)

// render bootstrap loader before router resolves any route
ReactDOM.render(<Bootstrap />, container)
// root.render(
//   <StrictMode>
//     <Bootstrap />
//   </StrictMode>
// )

// Render and re-render application on each location change
app.onLocationChange(({ location, route }) => {
  ReactDOM.render(<App location={location} route={route} />, container)
  // root.render(
  //   <StrictMode>
  //     <App location={location} route={route} />
  //   </StrictMode>
})

// init application
//  -> subscribe to history events
//  -> add global onclick handler for navigation
app.init({
  history,
  container,
  routes: {
    load: () => import('~/routes'),
  },
})

// export versions and environment variables for debug and information
window.VERSIONS = versions
window.ENV = env
